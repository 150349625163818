select[name="language"],
select[name="format"] {
    display: none;
}

.dropdown {
    width: 100%;
    height: 30px;
    position: relative;

    &.open {
        .dropdown__container {
            display: block;
        }

        .dropdown__icon {
            &-closed {
                display: none;
            }
            &-opened {
                display: block;
            }
        }
    }

    &__active-container {
        position: relative;
        font-size: 16px;
        //color: $swiss-color-grey-m2;
        padding: 8px 5px;
        border-bottom: 1px solid $swiss-color-grey-m3;
        
        &:hover {
            cursor: pointer;
            
            .dropdown__icon-closed {
                fill: $swiss-color-red;
            }
        }
    }

    &__title {
        display: inline-block;
        width: 100%;
        padding-right: 20px;
        padding-left: 15px;
        text-align: left;
    }

    &__container {
        display: none;
        position: absolute;
        background: #fff;
        width: 100%;
        text-align: left;
        z-index: 999999;
        padding: 20px 14px 20px 15px;
        border-radius: 2px;
        box-shadow: 0 8px 20px 0 rgba(83, 95, 105, 0.15);
    }

    &__icon {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);

        &-opened {
            display: none;
        }
    }

    &__item {
        font-size: 16px;
        display: inline-block;
        width: 100%;
        padding: 13px 15px;

        &.selected {
            font-weight: 600;
        }

        &.disabled {
            color: #a1aab5;
            cursor: default;
        }

        &:hover:not(.disabled) {
            cursor: pointer;
            background: $swiss-color-grey-m4;
            color: $swiss-color-red;
        }
    }
}