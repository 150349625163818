@import '../config/settings.scss';

body {
    font-family: 'MarkPro';
    font-size: 18px;
    font-weight: normal;
    color: #000;
    line-height: 1.1;
}

h1,
h2,
h3,
h4,
h5 {
  color: $swiss-color-red;
}

.headline {
    font-family: 'MarkPro';
    font-weight: 600;
    color: $swiss-color-red;
    line-height: 1;
}

.strong {
    font-family: 'MarkPro';
    font-weight: 600;
}

p {
    line-height: 1.3;
}