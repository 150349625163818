.header {
    width: 100%;
    padding: 10px 0;
    margin-bottom: 30px;
    margin-top: 20px;

    &__content {
        display: block;
        font-size: 50px;
        color: #000;
        line-height: 50px;
        font-size: 52px;
        font-weight: 600;

        span {
            font-weight: 200;
        }
    }

    &__image {
        display: block;
        text-align: right;
        margin-bottom: 30px; 

        img {
            width: 260px;
        }
    }
}